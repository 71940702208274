// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-case-study-js": () => import("/opt/build/repo/src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-pages-angebot-js": () => import("/opt/build/repo/src/pages/angebot.js" /* webpackChunkName: "component---src-pages-angebot-js" */),
  "component---src-pages-angebot-gross-js": () => import("/opt/build/repo/src/pages/angebot/gross.js" /* webpackChunkName: "component---src-pages-angebot-gross-js" */),
  "component---src-pages-angebot-hosting-js": () => import("/opt/build/repo/src/pages/angebot/hosting.js" /* webpackChunkName: "component---src-pages-angebot-hosting-js" */),
  "component---src-pages-angebot-klein-js": () => import("/opt/build/repo/src/pages/angebot/klein.js" /* webpackChunkName: "component---src-pages-angebot-klein-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/opt/build/repo/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

